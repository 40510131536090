import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
    const { user } = useAuth0();
    const [loginAbility, setLoginAbility] = useState();
    const [loginLoading, setLoginLoading] = useState(true);
    const navigate = useNavigate();

    const getLoginAbility = async () => {
        setLoginLoading(false);
        setLoginAbility(true);
    };

    useEffect(() => {
        if (user?.email) {
            getLoginAbility();
        }
    }, [user?.email]);

    useEffect(() => {
        if (user?.email && loginAbility === false) {
            navigate("/checkout");
        }
    }, [user?.email, loginAbility]);

    return (
        <LoginContext.Provider
            value={{ loginAbility, setLoginAbility, loginLoading }}
        >
            {children}
        </LoginContext.Provider>
    );
};

export const useLoginContext = () => useContext(LoginContext);
