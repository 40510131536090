import duke from "../../../assets/website/universities/duke.svg";
import stanford from "../../../assets/website/universities/stanford.svg";
import ut_austin from "../../../assets/website/universities/ut_austin.svg";
import notre_dame from "../../../assets/website/universities/notre_dame.svg";
import georgetown from "../../../assets/website/universities/georgetown.svg";
import React from "react";

const SocialProofUniversities = () => {
    return (
        <div className="as-container" style={{ backgroundColor: "white" }}>
            <div className="as-content">
                <div className="university-title" style={{ color: "#1e3a8a" }}>
                    Our students have been accepted to some of the top
                    universities in the world
                </div>
                <div className="university-roster">
                    <div className="university-image">
                        <img src={duke} alt="duke" />
                    </div>
                    <div className="university-image">
                        <img src={stanford} alt="stanford" />
                    </div>
                    <div className="university-image">
                        <img src={ut_austin} alt="ut_austin" />
                    </div>
                    <div className="university-image">
                        <img src={notre_dame} alt="notre_dame" />
                    </div>
                    <div className="university-image">
                        <img src={georgetown} alt="georgetown" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialProofUniversities;
