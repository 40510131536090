import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import Website from "./Website";
import App from "./App";
import Generative from "./Generative";

import "./components/shared/utils/GoogleAnalytics";

const isMainDomain = () =>
    window.location.hostname === "aliaeducation.com" ||
    window.location.hostname === "www.aliaeducation.com" ||
    window.location.pathname === "/blog" ||
    window.location.pathname === "/how-it-works" ||
    window.location.pathname === "/pricing" ||
    window.location.pathname === "/apply" ||
    window.location.pathname === "/apply-confirmation" ||
    window.location.pathname === "/checkout" ||
    window.location.pathname === "/signup";

const isGenerativeDomain = () =>
    window.location.hostname === "generative.aliaeducation.com" ||
    window.location.pathname === "/generative" ||
    window.location.pathname === "/problem-review";

// Global error handler
const errorHandler = (error) => {
    console.error("Uncaught error:", error);
    if (window.location.pathname !== "/error") {
        try {
            window.location.href = "/error";
        } catch (redirectError) {
            console.error("Failed to redirect to error page:", redirectError);
            alert("An unexpected error occurred. Please refresh the page.");
        }
    } else {
        // We're already on the error page and another error occurred
        console.error("Error occurred on error page:", error);
        alert("An unexpected error occurred. Please refresh the page.");
    }
};

// Handle runtime errors
window.addEventListener("error", errorHandler);

// Handle unhandled promise rejections
window.addEventListener("unhandledrejection", (event) => {
    errorHandler(event.reason);
});

const root = createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        {isMainDomain() ? (
            <Website />
        ) : (
            <Auth0Provider
                domain="auth0.platform.aliaeducation.com"
                clientId="TQn5yos8s6SeNwFtOydPoz5PS4V21oHW"
                redirectUri={window.location.origin}
                useRefreshTokens={true}
                cacheLocation="localstorage"
            >
                {isGenerativeDomain() ? <Generative /> : <App />}
            </Auth0Provider>
        )}
    </BrowserRouter>,
);
