// JourneyContext.js
import { createContext, useContext, useEffect, useState } from "react";
import GenericAsync from "../backend/GenericAsync";
import { useSettings } from "./SettingsContext";
import { useLoginContext } from "../auth/LoginContext";
import { useWebhookPoller } from "../backend/useWebhookPoller";

const JourneyContext = createContext(null);

export const JourneyProvider = ({ children }) => {
    const { settings, isModalOpen, settingsSaveAbility } = useSettings();
    const [journey, setJourney] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [generateAbility, setGenerateAbility] = useState(true);
    const [journeyLoading, setJourneyLoading] = useState(false);
    const { loginAbility } = useLoginContext();
    const { watchJob } = useWebhookPoller(
        `${backendUrl}/api/compute-step-results/status`,
    );

    const updateGenerateAbility = () => {
        if (journey) {
            const unfinishedCount = journey.filter(
                (step) => step.status !== "complete",
            ).length;
            setGenerateAbility(unfinishedCount <= 3);
        }
    };

    const getJourney = async () => {
        const getUserTimezone = () =>
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezone = getUserTimezone();
        setJourneyLoading(true);
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/login-workflow`,
                dataToSend: {
                    userEmail: settings.userEmail,
                    timezone: timezone,
                },
            });
            setJourney(response);
        } catch (error) {
            console.log(error.message);
        }
        setJourneyLoading(false);
    };

    const handleJobUpdate = (jobData) => {
        if (jobData.status === "finished" && jobData?.result?.journey_df) {
            setJourney(jobData.result.journey_df);
        }
    };

    const startWatchingJob = (jobId) => {
        return watchJob(jobId, handleJobUpdate);
    };

    useEffect(() => {
        if (isModalOpen) return;

        if (
            settings?.userEmail &&
            loginAbility &&
            settingsSaveAbility &&
            !isModalOpen
        ) {
            getJourney();
        }
    }, [
        settings?.userEmail,
        settingsSaveAbility,
        isModalOpen,
        refreshFlag,
        loginAbility,
    ]);

    useEffect(() => {
        if (settings?.userEmail && journey) {
            updateGenerateAbility();
        }
    }, [journey]);

    const refreshJourney = () => {
        setRefreshFlag((prevFlag) => !prevFlag);
    };

    return (
        <JourneyContext.Provider
            value={{
                journey,
                setJourney,
                refreshJourney,
                generateAbility,
                journeyLoading,
                startWatchingJob,
            }}
        >
            {children}
        </JourneyContext.Provider>
    );
};

export const useJourney = () => useContext(JourneyContext);
