import React, { useState, useEffect } from "react";
import "./WorkedExampleRenderer.css";
import GetImageSrc from "../../utils/GetImageSrc";
import MathJaxWrapper from "../../utils/MathJaxWrapper";

const SolutionStepContent = ({ content }) => {
    const renderContent = (content) => {
        // If content is a string, render it directly
        if (typeof content === "string") {
            return (
                <p
                    className="lesson-step-text"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            );
        }

        // If content is an object and has key-value pairs
        if (typeof content === "object" && content !== null) {
            return (
                <div className="lesson-step-content-list">
                    {Array.isArray(content)
                        ? content.map((explanation, index) => (
                              <div
                                  key={index}
                                  className="lesson-step-explanation"
                              >
                                  <span className="lesson-step-explanation-option">
                                      {index + 1}:
                                  </span>
                                  <span>{renderContent(explanation)}</span>
                              </div>
                          ))
                        : Object.entries(content).map(
                              ([option, explanation]) => (
                                  <div
                                      key={option}
                                      className="lesson-step-explanation"
                                  >
                                      <span className="lesson-step-explanation-option">
                                          {option}:
                                      </span>
                                      <span>
                                          {typeof explanation === "object" &&
                                          explanation !== null ? (
                                              <div className="lesson-nested-content">
                                                  {explanation.title && (
                                                      <div className="lesson-nested-title">
                                                          {explanation.title}
                                                      </div>
                                                  )}
                                                  <div className="lesson-nested-explanation">
                                                      {renderContent(
                                                          explanation.content,
                                                      )}
                                                  </div>
                                              </div>
                                          ) : (
                                              explanation
                                          )}
                                      </span>
                                  </div>
                              ),
                          )}
                </div>
            );
        }
    };
    return renderContent(content);
};

const WorkedExampleRenderer = ({ data }) => {
    const [step, setStep] = useState(0);
    const [solutionSteps, setSolutionSteps] = useState(0);
    const {
        prompt,
        question,
        multiple_choice_answers,
        correct_answer,
        solution_steps,
        image_url,
    } = data;

    const totalSolutionSteps = Object.keys(solution_steps || {}).length;

    const handleNext = () => {
        if (step === 0) {
            setStep(1);
            setSolutionSteps(1);
        } else if (step === 1) {
            if (solutionSteps < totalSolutionSteps) {
                setSolutionSteps((prev) => prev + 1);
            } else {
                setStep(2);
            }
        }
    };

    useEffect(() => {
        setStep(0);
        setSolutionSteps(0);
    }, [data]);

    return (
        <MathJaxWrapper>
            <div className="lesson-worked-example">
                {/* Step 0: Show the problem */}
                <div className="lesson-example-problem">
                    {image_url && (
                        <div
                            className="image-container"
                            style={{ marginBottom: "40px", maxWidth: "600px" }}
                        >
                            <img
                                src={GetImageSrc(image_url)}
                                alt="Question Image"
                            />
                        </div>
                    )}
                    <div
                        className="lesson-prompt"
                        dangerouslySetInnerHTML={{ __html: prompt }}
                    />
                    <div
                        className="lesson-question"
                        dangerouslySetInnerHTML={{ __html: question }}
                    ></div>
                    <div className="lesson-multiple-choice">
                        {multiple_choice_answers &&
                            Object.entries(multiple_choice_answers).map(
                                ([letter, text]) => (
                                    <div
                                        key={letter}
                                        className="lesson-answer-option"
                                    >
                                        <span className="lesson-answer-option-letter">
                                            {letter}.
                                        </span>
                                        <span>{text}</span>
                                    </div>
                                ),
                            )}
                    </div>
                </div>

                {/* Step 1: Show the solution steps */}
                {step >= 1 && (
                    <div className="lesson-solution-steps">
                        <h3 className="lesson-solution-steps-header">
                            Solution Steps:
                        </h3>
                        {Object.entries(solution_steps)
                            .slice(0, solutionSteps)
                            .map(([stepNum, stepData]) => (
                                <div key={stepNum} className="lesson-step">
                                    <div className="lesson-step-title">
                                        {stepNum}. {stepData.title}
                                    </div>
                                    <div className="lesson-step-content">
                                        <SolutionStepContent
                                            content={stepData.content}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                )}

                {/* Step 2: Show the correct answer */}
                {step >= 2 && (
                    <div className="lesson-correct-answer-section">
                        <div
                            className="lesson-correct-answer-header"
                            dangerouslySetInnerHTML={{
                                __html: `Correct Answer: ${correct_answer}`,
                            }}
                        />
                        {multiple_choice_answers && (
                            <div
                                className="lesson-correct-answer-text"
                                dangerouslySetInnerHTML={{
                                    __html: multiple_choice_answers[
                                        correct_answer
                                    ],
                                }}
                            />
                        )}
                    </div>
                )}

                {step < 2 && (
                    <button
                        onClick={handleNext}
                        className="lesson-next-step-button"
                    >
                        {step === 0
                            ? "Show Solution Steps"
                            : solutionSteps < totalSolutionSteps
                              ? "Next Step"
                              : "Show Answer"}
                    </button>
                )}
            </div>
        </MathJaxWrapper>
    );
};

export default WorkedExampleRenderer;
