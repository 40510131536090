// src/components/LessonPage.js
import React, { useState, useEffect } from "react";
import Calculator from "./header/Calculator";
import "./ProblemPage.css";
import ReferenceSheetButton from "./header/ReferenceSheetButton";
import { useLocation } from "react-router-dom";
import GenericAsync from "../backend/GenericAsync";
import { useUserProfile } from "../auth/UserProfileContext";
import formatString from "../utils/TextProcessing";
import ConfirmModal from "../popups/ConfirmModal";
import { useNavigate } from "react-router-dom";
import LessonWrapper from "./LessonWrapper";

const ReturnButton = () => {
    const navigate = useNavigate();
    const handleSaveAndExit = () => {
        navigate("/practice/lessons"); // Navigate to the Home Page
    };

    return (
        <button onClick={handleSaveAndExit} className={"header-button"}>
            Save/Exit
        </button>
    );
};

const LessonPage = () => {
    const [isDataLoading, setIsDataLoading] = useState(true); // Initialize as true to assume loading initially
    const [lesson, setLesson] = useState(null);
    const navigate = useNavigate();

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { profile } = useUserProfile();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const location = useLocation();
    const lastSegmentFromPath =
        location.pathname.split("/").filter(Boolean).pop() || "";

    const handleErrorConfirm = () => {
        navigate("/practice/lessons");
    };

    const handleGetLesson = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/get-lesson`,
                dataToSend: {
                    userEmail: profile.email,
                    category: lastSegmentFromPath,
                },
            });
            setLesson(response);
            setIsSuccess(true);
            setIsDataLoading(false);
        } catch (error) {
            setShowErrorModal(true);
            setIsSuccess(false);
            setIsDataLoading(false);
        }
    };

    const renderContent = () => {
        if (isDataLoading) {
            return <div>Loading...</div>;
        }
        return isSuccess ? <LessonWrapper lesson={lesson} /> : null;
    };

    useEffect(() => {
        handleGetLesson();
    }, []);

    return (
        <div className="test-page" style={{ overflow: "hidden" }}>
            <div
                className="top-bar"
                style={{ borderBottom: "1px solid #dee2e6", flexShrink: "0" }}
            >
                <div className="module-name" style={{ fontFamily: "DM Sans" }}>
                    <div>
                        {formatString(lastSegmentFromPath.replaceAll("-", " "))}
                    </div>
                </div>
                <div className="top-right-tools">
                    <Calculator />
                    <ReferenceSheetButton />
                    <ReturnButton />
                </div>
            </div>
            {renderContent()}
            <ConfirmModal
                isOpen={showErrorModal}
                onConfirm={handleErrorConfirm}
                message="Apologies! That lesson is not available at the moment. Please try again later."
                confirmMessage="Return to Lessons"
                showActions={true}
                navConsole={false}
            />
        </div>
    );
};

export default LessonPage;
