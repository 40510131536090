import React from "react";

export const TestInfoContainer = ({
    settings,
    setSettings,
    addNewUser = false,
    applyPage = false,
}) => {
    const APP_VERSION = process.env.REACT_APP_VERSION;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSettings({ ...settings, [name]: value });
    };

    function SATDates() {
        const dates = [
            "March 8, 2025",
            "May 3, 2025",
            "June 7, 2025",
            "August 23, 2025",
            "September 13, 2025",
            "October 4, 2025",
            "November 8, 2025",
            "December 6, 2025",
            "March 14, 2026",
            "May 2, 2026",
            "June 6, 2026",
        ];
        return dates;
    }

    const goalScoreLabels = {
        1200: "Beat the Average",
        1350: "Top 100 Colleges in the US",
        1450: "Top 30 US Colleges",
        1500: "Ivy+ Colleges",
        1550: "Cream of the Crop",
    };

    if (addNewUser) return;

    return (
        <div className="settings-unit">
            <div className="settings-container-title">Test Info</div>
            <div
                className="settings-text-container"
                style={{
                    width: applyPage && "100%",
                }}
            >
                <div
                    className={"settings-container-columns"}
                    style={{ gap: "20px" }}
                >
                    <div className="settings-text-column">
                        <div className="settings-column-title">
                            Date of {APP_VERSION.toUpperCase()}:
                            <span className="required-asterisk">*</span>
                        </div>
                        <div className={"settings-column-content"}>
                            <>
                                <select
                                    name="testDate"
                                    value={settings.testDate ?? ""}
                                    onChange={handleChange}
                                    className="settings-select-style"
                                >
                                    <option value={"none"}>None</option>
                                    {SATDates().map((date) => (
                                        <option key={date} value={date}>
                                            {date}
                                        </option>
                                    ))}
                                </select>
                            </>
                        </div>
                    </div>
                    {APP_VERSION === "sat" && (
                        <>
                            <div className="settings-text-column">
                                <div className="settings-column-title">
                                    Goal Score:
                                    <span className="required-asterisk">*</span>
                                </div>
                                <div className={"settings-column-content"}>
                                    <div className={"goal-score-container"}>
                                        <select
                                            value={settings.goalScore || "none"}
                                            onChange={(e) => {
                                                const value =
                                                    e.target.value === "none"
                                                        ? ""
                                                        : parseInt(
                                                              e.target.value,
                                                              10,
                                                          );
                                                handleChange({
                                                    target: {
                                                        name: "goalScore",
                                                        value: value,
                                                    },
                                                });
                                            }}
                                            className="settings-select-style"
                                            style={{ marginBottom: "15px" }}
                                        >
                                            <option value="none">None</option>
                                            {Array.from(
                                                { length: 13 },
                                                (_, i) => 1600 - i * 50,
                                            ).map((score) => (
                                                <option
                                                    key={score}
                                                    value={score}
                                                >
                                                    {score}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-text-column">
                                <div className="settings-column-title">
                                    Default Time Multiplier:
                                </div>
                                <>
                                    <select
                                        name="timeMultiplier"
                                        value={settings.timeMultiplier}
                                        onChange={handleChange}
                                        className="settings-select-style"
                                    >
                                        <option value="1x">1x</option>
                                        <option value="1.5x">1.5x</option>
                                        <option value="2x">2x</option>
                                    </select>
                                </>
                            </div>
                        </>
                    )}

                    <div className="settings-text-column">
                        <div className="settings-column-title">
                            Focus:
                            <span className="required-asterisk">*</span>
                        </div>
                        <>
                            <select
                                name="focus"
                                value={settings.focus}
                                onChange={handleChange}
                                className="settings-select-style"
                            >
                                <option value="none">Select an option</option>
                                <option value="math">Math</option>
                                <option value="reading-writing">
                                    Reading/Writing
                                </option>
                                <option value="both">Everything!</option>
                            </select>
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};
