const GenericAsync = async ({ backendUrl, dataToSend, jsonify = true }) => {
    const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
    }

    if (jsonify) {
        // Use text() and JSON.parse() instead of json() to ensure order preservation
        const text = await response.text();
        return JSON.parse(text);
    } else {
        return response;
    }
};

export default GenericAsync;
