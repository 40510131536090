/* global gtag */
window.dataLayer = window.dataLayer || [];
function gtag() {
    window.dataLayer.push(arguments);
}
gtag("js", new Date());

// Set up tracking based on subdomain
const domain = window.location.hostname;
const fullPath = window.location.href; // This will include protocol, domain, and path

if (domain === "aliaeducation.com" || domain === "www.aliaeducation.com") {
    gtag("config", "G-M9NC0JRTCJ", {
        page_location: fullPath, // Changed from page_path to page_location with full URL
        site_section: "main",
    });
} else if (domain === "generative.aliaeducation.com") {
    gtag("config", "G-M9NC0JRTCJ", {
        page_location: fullPath,
        site_section: "generative",
    });
} else {
    gtag("config", "G-M9NC0JRTCJ", {
        page_location: fullPath,
        site_section: "app",
    });
}

export const trackEvent = (eventName, parameters) => {
    gtag("event", eventName, parameters);
};
