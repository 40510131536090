import React, { useState, useRef } from "react";
import "./LessonWrapper.css";
import hamburgerIcon from "../../../assets/hamburger_menu.svg";
import collapseIcon from "../../../assets/collapse_array.svg";
import { useNavigate } from "react-router-dom";
import WorkedExampleRenderer from "./problem_components/WorkedExampleRenderer";
import MathJaxWrapper from "../utils/MathJaxWrapper";
import { hasLatexFormatting } from "../utils/TextProcessing";

const RecursiveRenderer = ({ data, depth = 0 }) => {
    const renderContent = (content) => {
        if (typeof content !== "string") return String(content);
        return (
            <MathJaxWrapper>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </MathJaxWrapper>
        );
    };

    if (Array.isArray(data)) {
        return (
            <ul className="recursive-list">
                {data.map((item, index) => (
                    <li key={index} className="recursive-list-item">
                        {typeof item === "object" && item !== null ? (
                            <RecursiveRenderer data={item} depth={depth + 1} />
                        ) : (
                            renderContent(item)
                        )}
                    </li>
                ))}
            </ul>
        );
    }

    if (typeof data === "object" && data !== null) {
        return Object.entries(data).map(([key, value]) => {
            // Check if this is a leaf node (bottom level)
            const isLeafNode = typeof value !== "object" || value === null;

            if (isLeafNode && depth === 0) {
                return <div key={key}>{renderContent(value)}</div>;
            }

            if (isLeafNode) {
                const keyString = key.charAt(0).toUpperCase() + key.slice(1);
                return (
                    <div key={key} className="leaf-content">
                        {hasLatexFormatting(keyString) ? (
                            renderContent(keyString)
                        ) : (
                            <i>{keyString} </i>
                        )}
                        <div className="leaf-value">{renderContent(value)}</div>
                    </div>
                );
            }

            return (
                <div key={key} className="nested-content">
                    {depth !== 0 && (
                        <div className="content-key">
                            {renderContent(
                                key.charAt(0).toUpperCase() + key.slice(1),
                            )}
                        </div>
                    )}
                    <div
                        className="content-value"
                        style={depth === 0 ? { paddingLeft: "0px" } : undefined}
                    >
                        <RecursiveRenderer data={value} depth={depth + 1} />
                    </div>
                </div>
            );
        });
    }

    return renderContent(data);
};

const LessonMenu = ({
    lesson,
    section,
    setSection,
    isCollapsed,
    setIsCollapsed,
}) => {
    const menuItems = Object.entries(lesson || {}).map(([key, value]) => ({
        id: key,
        title: value.title,
    }));

    const handleTitleClick = (id) => {
        setSection(id);
    };

    return (
        <>
            {!isCollapsed && (
                <div className="vertical-menu">
                    <div className="menu-header">
                        <button
                            className="collapse-button"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            aria-label="Collapse menu"
                        >
                            <img
                                src={collapseIcon}
                                alt="Collapse menu"
                                className="collapse-icon"
                            />
                        </button>
                    </div>
                    <nav className="menu-nav">
                        {menuItems.map((item) => (
                            <button
                                key={item.id}
                                onClick={() => handleTitleClick(item.id)}
                                className={`menu-item ${section === item.id ? "selected" : ""}`}
                                title={item.title}
                            >
                                {item.title}
                            </button>
                        ))}
                    </nav>
                </div>
            )}
        </>
    );
};

const LessonWrapper = ({ lesson }) => {
    const [section, setSection] = useState("0");
    const [isCollapsed, setIsCollapsed] = useState(false);
    const contentRef = useRef(null);

    const subsectionDict = lesson[section]?.subsection_dict;
    const sectionKeys = Object.keys(lesson || {});
    const currentIndex = sectionKeys.indexOf(section);

    const navigate = useNavigate();

    const handleNavigation = (direction) => {
        const newSection = sectionKeys[currentIndex + direction];
        if (newSection) {
            setSection(newSection);
            contentRef.current?.scrollTo(0, 0);
        }
    };

    return (
        <div className="lesson-container">
            <LessonMenu
                lesson={lesson}
                section={section}
                setSection={setSection}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
            />

            <div className="content-area" ref={contentRef}>
                {isCollapsed && (
                    <button
                        className="expand-button"
                        onClick={() => setIsCollapsed(false)}
                        aria-label="Expand menu"
                    >
                        <img
                            src={hamburgerIcon}
                            alt="Expand menu"
                            className="collapse-icon"
                        />
                    </button>
                )}
                {section !== null && subsectionDict && (
                    <div className="content-wrapper">
                        <div className="content-title">
                            {lesson[section]?.title}
                        </div>
                        <div className="sections-container">
                            {lesson[section]?.worked_example ? (
                                <div className="content-body">
                                    <WorkedExampleRenderer
                                        data={subsectionDict}
                                    />
                                </div>
                            ) : (
                                Object.entries(subsectionDict).map(
                                    ([key, value]) => (
                                        <pre
                                            key={key}
                                            className="content-body"
                                            style={{ fontFamily: "DM Sans" }}
                                        >
                                            <div
                                                className={
                                                    "content-key top-level-key"
                                                }
                                            >
                                                {key}
                                            </div>
                                            <RecursiveRenderer
                                                data={{ [key]: value }}
                                            />
                                        </pre>
                                    ),
                                )
                            )}
                        </div>
                        <div className="lesson-navigation-buttons">
                            <button
                                onClick={() => handleNavigation(-1)}
                                disabled={currentIndex === 0}
                                className="lesson-nav-button"
                            >
                                Previous
                            </button>
                            {currentIndex === sectionKeys.length - 1 ? (
                                <button
                                    onClick={() =>
                                        navigate("/practice/lessons")
                                    }
                                    className="lesson-nav-button"
                                >
                                    Return to Lessons
                                </button>
                            ) : (
                                <button
                                    onClick={() => handleNavigation(1)}
                                    className="lesson-nav-button"
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LessonWrapper;
