// useWebhookPoller.js
import { useCallback, useEffect, useRef } from "react";

class WebhookPollerManager {
    constructor() {
        this.jobs = new Map(); // Map of jobId -> { callback, status }
        this.pollingInterval = null;
        this.isPolling = false;
    }

    addJob(jobId, callback) {
        this.jobs.set(jobId, {
            callback,
            status: "pending",
        });

        if (!this.isPolling) {
            this.startPolling();
        }
    }

    removeJob(jobId) {
        this.jobs.delete(jobId);

        if (this.jobs.size === 0) {
            this.stopPolling();
        }
    }

    startPolling() {
        if (this.isPolling) {
            return;
        }
        this.isPolling = true;
    }

    stopPolling() {
        this.isPolling = false;
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
            this.pollingInterval = null;
        }
    }

    clearAllJobs() {
        this.jobs.clear();
        this.stopPolling();
    }

    getActiveJobs() {
        return Array.from(this.jobs.keys());
    }
}

// Singleton instance
const pollerManager = new WebhookPollerManager();

export const useWebhookPoller = (baseUrl) => {
    const pollingRef = useRef(null);

    const checkJobStatus = useCallback(
        async (jobId) => {
            try {
                const response = await fetch(`${baseUrl}/${jobId}`);
                const data = await response.json();
                return { jobId, data };
            } catch (error) {
                console.error(`Error checking status for job ${jobId}:`, error);
                return {
                    jobId,
                    data: { status: "error", error: error.message },
                };
            }
        },
        [baseUrl],
    );

    const startPolling = useCallback(() => {
        if (pollingRef.current) return;

        pollingRef.current = setInterval(async () => {
            if (!pollerManager.isPolling || pollerManager.jobs.size === 0) {
                clearInterval(pollingRef.current);
                pollingRef.current = null;
                return;
            }

            const activeJobs = pollerManager.getActiveJobs();
            const statusChecks = activeJobs.map(checkJobStatus);

            const results = await Promise.all(statusChecks);

            results.forEach(({ jobId, data }) => {
                const jobInfo = pollerManager.jobs.get(jobId);
                if (!jobInfo) return;

                // Call the callback with the status
                jobInfo.callback(data);

                // Remove completed or failed jobs
                if (
                    data.status === "finished" ||
                    data.status === "failed" ||
                    data.status === "error"
                ) {
                    pollerManager.removeJob(jobId);
                }
            });
        }, 2000); // Poll every 2 seconds
    }, [checkJobStatus]);

    const watchJob = useCallback(
        (jobId, callback) => {
            if (!jobId || !callback) return;

            pollerManager.addJob(jobId, callback);
            startPolling();

            // Return cleanup function
            return () => pollerManager.removeJob(jobId);
        },
        [startPolling],
    );

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
            }
        };
    }, []);

    return {
        watchJob,
        getActiveJobs: () => pollerManager.getActiveJobs(),
        clearAllJobs: () => pollerManager.clearAllJobs(),
    };
};
