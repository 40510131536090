import RenderWithLineBreaks from "../utils/RenderWithLineBreaks";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericAsync from "../backend/GenericAsync";
import _ from "lodash";
import formatString from "../utils/TextProcessing";

const SelectLesson = () => {
    const navigate = useNavigate();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [flattenedCategories, setFlattenedCategories] = useState({});

    const [selection, setSelection] = useState({
        moduleType: "",
        questionCategory: "",
    });

    const fetchCategories = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/retrieve-categories`,
                dataToSend: {},
            });

            // Flatten and alphabetize the nested structure
            const flattened = {};
            Object.entries(response).forEach(([key, superCategories]) => {
                flattened[key] = _.sortBy(
                    _.flatMap(Object.values(superCategories)),
                );
            });

            setFlattenedCategories(flattened);
            const defaultCat = "reading_writing";
            // Set initial values only after data is loaded
            setSelection({
                moduleType: defaultCat,
                questionCategory: flattened[defaultCat]?.[0] || "",
            });
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelection((prev) => ({
            ...prev,
            [name]: value,
            // When moduleType changes, set questionCategory to first available category
            ...(name === "moduleType"
                ? {
                      questionCategory: flattenedCategories[value]?.[0] || "",
                  }
                : {}),
        }));
    };

    const handleGoToLesson = () => {
        try {
            navigate(
                `/practice/lessons/${selection.questionCategory.replaceAll(" ", "-").replaceAll(",", "")}`,
            );
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <div className="dropdown-container" style={{ marginTop: "20px" }}>
            <div className="dropdown-container-section-title">
                Question Type
            </div>
            <select
                name="moduleType"
                value={selection.moduleType}
                onChange={handleChange}
                className="select-style"
            >
                <option value="reading_writing">Reading and Writing</option>
                <option value="math">Math</option>
            </select>
            <div
                className="dropdown-container-section-title"
                style={{ marginTop: "20px" }}
            >
                Category
            </div>
            <select
                name="questionCategory"
                value={selection.questionCategory}
                onChange={handleChange}
                className="select-style"
                disabled={!flattenedCategories[selection.moduleType]}
            >
                {flattenedCategories[selection.moduleType]?.map(
                    (category, index) => (
                        <option key={`${category}-${index}`} value={category}>
                            {formatString(_.startCase(category))}
                        </option>
                    ),
                )}
            </select>
            <div>
                <button
                    className="orange-button"
                    onClick={handleGoToLesson}
                    disabled={
                        !selection.moduleType || !selection.questionCategory
                    }
                    style={{ marginTop: "20px" }}
                >
                    Go To Lesson
                </button>
            </div>
        </div>
    );
};

const Lessons = () => {
    function returnCopy() {
        const text_copy =
            "" +
            "Lessons are offered by question category. Each lesson includes an overview of " +
            "the question category, an explanation of key concepts, " +
            "instructions on how to approach that type of question, " +
            "worked examples, and top tips. For Math topics, the lessons also include " +
            "guidance on how to use Desmos most effectively." +
            "\n\n" +
            "Lessons are untimed, and you can review each one as many times as you'd like. " +
            "\n";

        return text_copy;
    }

    return (
        <div className={"two-col-body-content"}>
            <div className={"two-col-body-content-text"}>
                {RenderWithLineBreaks(returnCopy())}
            </div>
            <div
                className={"two-col-body-content-container"}
                style={{ maxWidth: "500px" }}
            >
                <div className={"task-container"} style={{ marginTop: "0px" }}>
                    <div className={"overlap"} style={{ width: "100%" }}>
                        <div className="rectangle">
                            <div>
                                <div className="task-container-title">
                                    {"LESSONS"}
                                </div>
                            </div>
                        </div>
                        <div
                            className="current-task-container"
                            style={{
                                justifyContent: "flex-start",
                                minHeight: "140px",
                            }}
                        >
                            <SelectLesson />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Lessons;
