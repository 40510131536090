import React, { useMemo, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import HomePage from "./components/shared/home/HomePage";
import ProblemPage from "./components/shared/problem_console/ProblemPage";
import Practice from "./components/shared/practice/Practice";
import { UserProfileProvider } from "./components/shared/auth/UserProfileContext"; // adjust the path as necessary
import { JourneyProvider } from "./components/shared/common/JourneyContext";
import { SettingsProvider } from "./components/shared/common/SettingsContext";

import Settings from "./components/shared/settings/Settings";
import ErrorPage from "./components/shared/common/ErrorPage";
import { updateIntercomVisibility } from "./components/shared/common/intercom/IntercomUtils";
import ErrorBoundary from "./components/shared/common/ErrorBoundary";
import { ErrorProvider } from "./components/shared/common/ErrorContext";
import { LoginProvider } from "./components/shared/auth/LoginContext";
import AdminPage from "./components/shared/admin/AdminPage";
import { useLoginContext } from "./components/shared/auth/LoginContext";
import { useMetaPixel } from "./components/shared/advertising/MetaPixel";
import { useTikTokPixel } from "./components/shared/advertising/TikTokPixel";
import LessonPage from "./components/shared/problem_console/LessonPage"; // Adjust the import path as needed

function AppRoutes() {
    const { loginAbility, loginLoading } = useLoginContext();
    const { isAuthenticated } = useAuth0();
    const location = useLocation();

    const practiceRoutesConfig = [
        { path: "/practice/tests", stepType: "tests" },
        { path: "/practice/modules", stepType: "modules" },
        { path: "/practice/problem-sets", stepType: "problem-sets" },
        { path: "/practice/lessons", stepType: "lessons" },
    ];

    const intercomEnabledPaths = useMemo(() => {
        const practicePaths = practiceRoutesConfig.map((route) => route.path);
        return ["/", "/settings", ...practicePaths];
    }, []); /// this will only be computed once

    useEffect(() => {
        const shouldShowIntercom =
            intercomEnabledPaths.includes(location.pathname) && isAuthenticated;
        updateIntercomVisibility(shouldShowIntercom);
    }, [location, intercomEnabledPaths, isAuthenticated]);

    if (loginLoading || loginAbility === null) {
        return <div>Loading...</div>;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    loginAbility === false ? (
                        <Navigate to="/checkout" />
                    ) : (
                        <HomePage />
                    )
                }
            />
            {practiceRoutesConfig.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={<Practice stepType={route.stepType} />}
                />
            ))}
            <Route path="/practice">
                <Route path="lessons/:lessonId" element={<LessonPage />} />
                <Route path=":stepType/:testId" element={<ProblemPage />} />
            </Route>
            <Route path="/settings" element={<Settings />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/admin" element={<AdminPage />} />
        </Routes>
    );
}

function App() {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
    useMetaPixel();
    useTikTokPixel();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <ErrorProvider>
            <ErrorBoundary>
                <LoginProvider>
                    <UserProfileProvider>
                        <SettingsProvider>
                            {/*<SocketProvider>*/}
                            <JourneyProvider>
                                <AppRoutes />
                            </JourneyProvider>
                            {/*</SocketProvider>*/}
                        </SettingsProvider>
                    </UserProfileProvider>
                </LoginProvider>
            </ErrorBoundary>
        </ErrorProvider>
    );
}

export default App;
