import "./JourneyStepModal.css";
import SelectStepContainer from "../practice/existing/SelectStepContainer";
import React, { useEffect, useRef } from "react";

const JourneyStepModal = ({ isStepOpen, setIsStepOpen, step }) => {
    const modalContentRef = useRef(null);

    useEffect(() => {
        if (modalContentRef.current) {
            const updateMaxHeight = () => {
                const parentElement = modalContentRef.current.parentElement;
                if (parentElement) {
                    const parentHeight = parentElement.clientHeight;
                    modalContentRef.current.style.maxHeight = `${parentHeight * 0.8}px`; // Adjust the multiplier as needed
                }
            };

            // Initial update
            updateMaxHeight();

            // Update on window resize
            window.addEventListener("resize", updateMaxHeight);

            // Cleanup on component unmount
            return () => window.removeEventListener("resize", updateMaxHeight);
        }
    }, [isStepOpen]);

    if (!isStepOpen) return null;

    return (
        <div className="step-modal-backdrop">
            <div className="step-modal-content" ref={modalContentRef}>
                <button
                    className="journey-modal-close-button"
                    onClick={() => setIsStepOpen(false)}
                >
                    X
                </button>
                <SelectStepContainer
                    activeStep={step}
                    stepType={step.stepType}
                    stepModal={true}
                />
            </div>
        </div>
    );
};

export default JourneyStepModal;
